module.exports = {
    'title': '管理电子邮件订阅',
    'label_email': '邮箱地址',
    'label_unsub_choice': '您已选择：',
    'label_unsub_marketing': '取消订阅所有促销优惠信息',
    'label_unsub_monthly': '取消订阅会员月度账户汇总信息',
    'label_btn_submit': '取消订阅',
    'label_btn_redirect': '首页',
    'notification_success_title': '成功取消订阅',
    'label_success_email': '已从以下邮件列表中取消订阅：',
    'bullet_ayana_rewards_promotion': '阿雅娜优享会员计划促销优惠',
    'bullet_ayana_rewards_monthly': '阿雅娜优享会员月度账户汇总',
    'label_success_resubscribe': '您可以通过个人信息页面重新订阅。',
    'notification_error_title': '错误'
}