import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import Layout from "../layouts/zh";
import SEO from "../components/seo";
import Unsubscribe from "../services/Unsubscribe";
import langData from "../data/static-pages/pages/unsubscribe/zh";

const UnsubscribePage = ({ ...props }) => {

    return (

        <Layout location={props.location}>
            <SEO title="Unsubscribe " keyword="Unsubscribe" decription="Unsubscribe" />
            <Unsubscribe
                langData={langData}
                props={props}
                redirectErrorUrl="/zh"
            />
        </Layout>
    );
}

export default UnsubscribePage;